import Image from 'src/components/Image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';

const StyledImage = styled(Image)`
    height: 100%;
    width: 100%;
`;

const Wrapper = styled('div')`
    ${theme.spacing.mobile._12('padding-left')};
    ${theme.spacing.tablet._0('padding-left')};
    ${theme.spacing.desktop._0('padding-left')};

    ${theme.spacing.mobile._12('padding-right')};
    ${theme.spacing.tablet._0('padding-right')};
    ${theme.spacing.desktop._0('padding-right')};

    ${theme.spacing.mobile._40('margin-bottom')};
    ${theme.spacing.tablet._40('margin-bottom')};
    ${theme.spacing.desktop._40('margin-bottom')};
`;

const ImageSection = props => {
    const image = props.data.postImageSection;

    return (
        <Wrapper>
            <StyledImage objectFit="cover" fluid={image && image['medium']} />
        </Wrapper>
    );
};

export default ImageSection;

ImageSection.propTypes = {
    data: PropTypes.shape({
        module: PropTypes.string,
        postImageSection: PropTypes.arrayOf(PropTypes.object),
    }),
    module: PropTypes.string,
};

ImageSection.defaultProps = {
    data: {
        module: '',
        postImageSection: {
            heading: '',
            text: [],
        },
    },
    module: '',
};
